export const query_Projects = gql`
  query Projects {
    Projects {
      ID
      name
      _ {
        created
      }
    }
  }
`;

export const query_Projectlinked = gql`
  query Projectlinked {
    Projectlinked {
      ID
      name
      control {
        mode
      }
      _ {
        created
      }
    }
  }
`;

export const query_Project = gql`
    query Project($ID: String!) {
        Project(ID: $ID) {
            ID
            name
            ownerID
            locations{
              location
              count
            }
            specialities
            tasks{
                ID
                description
                startDate
                author
                locations
                specialities
                status
                discussionID
            }
            control{
              mode
              value
              duration
            }
            _ {
                created
            }
        }
    }
`;

export const query_Task = gql`
    query Task($ID: String!, $taskID: String!) {
        Task(ID: $ID, taskID: $taskID) {
          ID
          description
          startDate
          locations
          specialities
          author
          status
          discussionID
        }
    }
`;

export const query_CreateProject = gql`
    mutation CreateProject($params: String!){
        CreateProject(params: $params)
    }
`;

export const query_UpdateProject = gql`
    mutation UpdateProject($ID: String!, $params: String!){
        UpdateProject(ID: $ID, params: $params)
    }
`;

export const query_CreateTask = gql`
    mutation CreateTask($projectID: String!, $params: String!){
        CreateTask(projectID: $projectID, params: $params,)
    }
`;

export const query_UpdateTask = gql`
    mutation UpdateTask($projectID: String!, $taskID: String! $params: String!){
      UpdateTask(projectID: $projectID, taskID: $taskID, params: $params,)
    }
`;

export const query_InvalidateTask = gql`
    mutation InvalidateTask($projectID: String!, $taskID: String!){
        InvalidateTask(projectID: $projectID, taskID: $taskID,)
    }
`;

export const query_ValidateTask = gql`
    mutation ValidateTask($projectID: String!, $taskID: String!){
        ValidateTask(projectID: $projectID, taskID: $taskID,)
    }
`;

export const query_GetAutorisation = gql`
    mutation GetAutorisation($projectID: String!, $digicode: String){
      GetAutorisation(projectID: $projectID, digicode: $digicode,)
}
`;

export const query_IsAuthorized = gql`
    mutation IsAuthorized($projectID: String!){
      IsAuthorized(projectID: $projectID)
    }
`;

export const query_IncrementLocationCount = gql`
    mutation IncrementLocationCount($projectID: String!, $location: String!){
      IncrementLocationCount(projectID: $projectID, location: $location)
    }
`

export const query_CreateWord = gql`
    mutation CreateWord($params: String, $companyID: String, $reportID: String, $logID: String){
      CreateWord(params: $params, companyID: $companyID, reportID: $reportID, logID: $logID)
    }
`
